import slotStatus from 'src/constants/slotStatus';
import {
  APPOINTMENT_SELECTED,
  SLOT_DELETED_BY_ID,
  DOCTOR_SLOTS_FETCHED_BY_DATE,
  FETCH_ALL_APPOINTMENTS_FOR_BRANCH,
  FETCH_ALL_APPOINTMENTS_FOR_DOCTOR,
  REMOVE_SELECTED_APPOINTMENT,
  UPDATE_APPOINTMENT,
  BULK_SLOT_DELETE,
  BOOK_APPOINTMENT,
  DELETE_APPOINTMENT,
  UPDATE_APPOINTMENT__BY_DOCTOR,
  READ_TODAYS_QUEUE_FOR_DOCTOR,
  PATIENTS_PREVIOUS_APPOINTMENTS
} from '../actions/appointment';
import { UPDATE_WORKSPACE_BRANCH } from '../actions/company';

const initialState = {
  appointments: [],
  patientsAppointments: [],
  totalCount: 0,
  selectedAppointment: {},
  slotsByDate: {},
  queue: []
};

const deleteFromArray = (arrayData, matchingValues, matchingKey) => {
  let arrays = arrayData;
  matchingValues.forEach((item) => {
    const index = arrays.findIndex(
      (arrayItem) => arrayItem[matchingKey] === item
    );
    arrays.splice(index, 1);
  });
  return arrays;
};

const updateInArray = (arrayData, matchingObject, slotId) => {
  let arrays = [...arrayData];
  const index = arrays.findIndex((arrayItem) => arrayItem['slotId'] === slotId);
  if (index !== -1) {
    arrays.splice(index, 1, {
      ...arrays[index],
      appointment: matchingObject.appointment,
      patient: matchingObject.patientDetails
    });
  }

  return arrays;
};

const removeAppointment = (arrayData, matchingObject, slotId) => {
  let arrays = [...arrayData];
  const index = arrays.findIndex((arrayItem) => arrayItem['slotId'] === slotId);
  if (index !== -1) {
    arrays.splice(index, 1, {
      ...arrays[index],
      status: slotStatus.OPEN.value,
      appointment: null,
      patient: null
    });
  }

  return arrays;
};

const updatedExistingSlotValue = (arrayData, matchingObject) => {
  let arrays = [...arrayData];
  const index = arrays.findIndex(
    (arrayItem) => arrayItem['slotId'] === matchingObject.appointment.slotId
  );
  arrays.splice(index, 1, {
    ...arrays[index],
    status: slotStatus.BOOKED.value,
    appointment: matchingObject.appointment,
    patient: matchingObject.patientDetails
  });

  return arrays;
};

const updatedAppointmentStatus = (arrayData, matchingObject) => {
  let arrays = [...arrayData];
  const index = arrays.findIndex(
    (arrayItem) =>
      arrayItem.appointment.appointmentId ===
      matchingObject.appointment.appointmentId
  );

  if (index !== -1) {
    arrays.splice(index, 1, matchingObject);
  }
  return arrays;
};

const updateOrAddDoctorQueue = (arrayData, payload) => {
  let arrays = [...arrayData];
  const index = arrays.findIndex(
    (arrayItem) => arrayItem.doctorId === payload.doctorId
  );
  if (index === -1) {
    arrays = [...arrays, { ...payload }];
  } else {
    arrays.splice(index, 1, { ...payload });
  }
  return arrays;
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_ALL_APPOINTMENTS_FOR_DOCTOR:
      return {
        ...state,
        appointments: action.payload,
        totalCount: action.totalCount
      };
    case READ_TODAYS_QUEUE_FOR_DOCTOR:
      return {
        ...state,
        queue: updateOrAddDoctorQueue(state.queue, action.payload)
      };
    case FETCH_ALL_APPOINTMENTS_FOR_BRANCH:
      return {
        ...state,
        appointments: action.payload,
        totalCount: action.totalCount
      };
    case APPOINTMENT_SELECTED:
      return {
        ...state,
        selectedAppointment: action.payload
      };
    case REMOVE_SELECTED_APPOINTMENT:
      return {
        ...state,
        selectedAppointment: {}
      };
    case UPDATE_APPOINTMENT: {
      return {
        ...state,
        slotsByDate: {
          ...state.slotsByDate,
          slots: updateInArray(
            state.slotsByDate.slots,
            action.payload,
            action.slotId
          )
        }
      };
    }

    case UPDATE_APPOINTMENT__BY_DOCTOR:
      return {
        ...state,
        appointments: updatedAppointmentStatus(
          state.appointments,
          action.payload
        )
      };
    case DELETE_APPOINTMENT:
      return {
        ...state,
        slotsByDate: {
          ...state.slotsByDate,
          slots: removeAppointment(
            state.slotsByDate.slots,
            action.payload,
            action.slotId
          )
        }
      };
    case DOCTOR_SLOTS_FETCHED_BY_DATE: {
      return {
        ...state,
        slotsByDate: {
          ...action.payload,
          slots: action.payload.slots ? [...action.payload.slots] : []
        }
      };
    }
    case SLOT_DELETED_BY_ID:
      return {
        ...state,
        slotsByDate: {
          ...state.slotsByDate,
          slots: deleteFromArray(
            state.slotsByDate.slots,
            action.payload,
            'slotId'
          )
        }
      };
    case BULK_SLOT_DELETE:
      return {
        ...state,
        slotsByDate: {
          ...state.slotsByDate,
          slots: deleteFromArray(
            state.slotsByDate.slots,
            action.payload,
            'slotId'
          )
        }
      };
    case BOOK_APPOINTMENT:
      return {
        ...state,
        slotsByDate: {
          ...state.slotsByDate,
          slots: updatedExistingSlotValue(
            state.slotsByDate.slots,
            action.payload
          )
        }
      };
    case PATIENTS_PREVIOUS_APPOINTMENTS:
      return {
        ...state,
        patientsAppointments: action.payload
      };
    case UPDATE_WORKSPACE_BRANCH:
      return initialState;
    default:
      return state;
  }
};
