// import { storeToken } from '../../helpers/asyncstorage';
import { readData, readNonJSONResponse } from 'src/helpers/db';
import { showErrorToaster } from '../../helpers/toaster';

export const HTTP_ERROR_RESPONSE = 'HTTP_ERROR_RESPONSE';
export const LOADING_START = 'LOADING_START';
export const LOADING_STOP = 'LOADING_STOP';
export const GENERATED_CAPTCHA = 'GENERATED_CAPTCHA';

export const httpErrorResponse = (e, logoutCallback) => {
  return async (dispatch) => {
    dispatch({ type: LOADING_STOP });
    dispatch({
      type: HTTP_ERROR_RESPONSE,
      payload: e
    });
    if (e.status === 401 || e.status === 'UNAUTHORIZED') {
      sessionStorage.removeItem('TOKEN');
      dispatch({
        type: 'USER_LOGOUT'
      });
      if (typeof logoutCallback === 'function') logoutCallback();
    }

    if (e.errorMessage) showErrorToaster(e.errorMessage);
  };
};

export const generateCaptcha = () => {
  return async (dispatch) => {
    dispatch({ type: 'LOADING_START' });
    await readData('captcha/v1/generate')
      .then(async (response) => {
        dispatch({
          type: GENERATED_CAPTCHA,
          payload: {
            ...response,
            data: 'data:image/png;base64,' + response.data
          }
        });
        dispatch({ type: LOADING_STOP });
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

export const downloadFile = (attachemntId, typeArray) => {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_START });
    readNonJSONResponse(
      `patients/v1/patient/attachment/${attachemntId}`,
      getState().user.userDetails.token
    )
      .then((response) => response.blob())
      .then(async (response) => {
        const url = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = url;
        link.download =
          typeArray[typeArray.length - 2] +
          '.' +
          typeArray[typeArray.length - 1];
        link.click();
        dispatch({ type: LOADING_STOP });
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

export const showFile = (attachemntId, linkType, callback) => {
  return (dispatch, getState) => {
    // dispatch({ type: LOADING_START });
    let fileType = 'image/png';
    if (linkType === 'pdf') {
      fileType = 'application/pdf';
    }
    readNonJSONResponse(
      `patients/v1/patient/attachment/${attachemntId}`,
      getState().user.userDetails.token
    )
      .then((response) => response.blob())
      .then(async (response) => {
        const file = new Blob([response], { type: fileType });
        const fileURL = URL.createObjectURL(file);
        callback(fileURL);
        // dispatch({ type: LOADING_STOP });
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};
