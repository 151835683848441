export const SET_SELECTED_TOOL = 'SET_SELECTED_TOOL';

export const setSelectedTool = (selectedTool) => {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_TOOL,
      payload: selectedTool
    });
  };
};
