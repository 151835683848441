// import { storeToken } from '../../helpers/asyncstorage';
// import { dataFormatDateWise } from 'src/helpers/utils';
import attachmentTypes from 'src/constants/attachmentTypes';
import {
  createDataWithToken,
  createDataWithTokenNoContentType,
  readDataWithToken
} from '../../helpers/db';
import { httpErrorResponse, LOADING_START, LOADING_STOP } from './app';
import { showSuccessToaster } from 'src/helpers/toaster';
import { isNumeric } from 'src/helpers/utils';

export const ADD_PATIENT = 'ADD_PATIENT';
export const FETCHED_ALL_PATIENT_WITH_TOKEN = 'FETCHED_ALL_PATIENT_WITH_TOKEN';
export const FETCH_PATIENT_HISTORY = 'FETCH_PATIENT_HISTORY';
export const PATIENT_HISTORY_ADDED = 'PATIENT_HISTORY_ADDED';
export const PULL_MORE_HISTORY_FOR_PATIENT = 'PULL_MORE_HISTORY_FOR_PATIENT';
export const FETCH_SPECIFIC_TYPE_HISTORY = 'FETCH_SPECIFIC_TYPE_HISTORY';
export const FETCH_PATIENT_BY_PATIENT_ID = 'FETCH_PATIENT_BY_PATIENT_ID';
export const RESET_PATIENT_HISTORY = 'RESET_PATIENT_HISTORY';
export const PATIENT_VITALS_WITH_APPOINTMENT_ID =
  'PATIENT_VITALS_WITH_APPOINTMENT_ID';
export const SET_SELECTED_PATIENT = 'SET_SELECTED_PATIENT';
export const RESET_SELECTED_PATIENT = 'RESET_SELECTED_PATIENT';

export const createPatient = (formData, callback, errorCallback) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails;
    const userProfile = userDetails.user.profile;
    dispatch({ type: LOADING_START });
    const currentDate = new Date().toISOString();
    const requestData = {
      patientId: 0,
      patientNumber: 'patientNumber',
      ...formData,
      middleName: formData.middleName ? formData.middleName : '',
      registrationDate: currentDate,
      branchId: getState().company.workspaceBranch,
      companyId: getState().company.companyDetails.companyId,
      createdBy: userProfile.userName,
      createdDate: currentDate,
      modifiedBy: userProfile.userName,
      modifiDate: currentDate
    };

    createDataWithToken('patients/v1/patient/save', userDetails.token, {
      payload: requestData
    })
      .then((response) => {
        dispatch({ type: ADD_PATIENT, payload: response });
        dispatch({ type: LOADING_STOP });
        callback(response.data);
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
        if (typeof errorCallback === 'function') {
          errorCallback();
        }
      });
  };
};

export const findPatientByPatientId = (patientId) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails;
    const companyId = getState().company.companyDetails.companyId;
    const requestData = {
      pageNo: 0,
      pageSize: 5,
      sortBy: 'firstName',
      sortDirection: 'ASC',
      filters: {
        patientId: patientId,
        companyId: companyId
      }
    };

    dispatch({ type: LOADING_START });
    createDataWithToken(
      'patients/v1/patient/find',
      userDetails.token,
      requestData
    )
      .then((response) => {
        dispatch({
          type: FETCH_PATIENT_BY_PATIENT_ID,
          payload: response.data[0]
        });
        dispatch({ type: LOADING_STOP });
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

export const readAllPatientsForBranch = (value) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails;
    const companyId = getState().company.companyDetails.companyId;
    let filters = {
      firstName: value,
      companyId: companyId
    };

    if (isNumeric(value)) {
      filters = {
        mobileNumber: value,
        companyId: companyId
      };
    }
    const requestData = {
      pageNo: 0,
      pageSize: 5,
      sortBy: 'firstName',
      sortDirection: 'ASC',
      filters: {
        ...filters
      }
    };

    dispatch({ type: LOADING_START });
    createDataWithToken(
      'patients/v1/patient/find',
      userDetails.token,
      requestData
    )
      .then((response) => {
        dispatch({
          type: FETCHED_ALL_PATIENT_WITH_TOKEN,
          payload: response.data
        });
        dispatch({ type: LOADING_STOP });
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

export const fetchPatientHistory = (
  patientId,
  pageNo,
  pageSize,
  sortDirection,
  sortBy,
  callback,
  errorCallback
) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails;
    dispatch({ type: LOADING_START });
    readDataWithToken(
      `patients/v1/patient/${patientId}/history?pageNo=${pageNo}&pageSize=${pageSize}&sortDirection=${sortDirection}&sortBy=${sortBy}`,
      userDetails.token
    )
      .then(async (response) => {
        // let dataArray = await dataFormatDateWise(response.data);
        dispatch({
          type: FETCH_PATIENT_HISTORY,
          payload: {
            patientId: patientId,
            ...response
          }
        });
        dispatch({ type: LOADING_STOP });
        callback({
          patientId: patientId,
          ...response
        });
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
        errorCallback(e);
      });
  };
};

export const pullMorePatientHistory = (
  patientId,
  pageNo,
  pageSize,
  sortDirection,
  sortBy,
  callback,
  errorCallback
) => {
  return (dispatch, getState) => {
    if (pageNo > 0) {
      const userDetails = getState().user.userDetails;
      dispatch({ type: LOADING_START });
      readDataWithToken(
        `patients/v1/patient/${patientId}/history?pageNo=${pageNo}&pageSize=${pageSize}&sortDirection=${sortDirection}&sortBy=${sortBy}`,
        userDetails.token
      )
        .then(async (response) => {
          dispatch({
            type: PULL_MORE_HISTORY_FOR_PATIENT,
            payload: response.data
          });
          dispatch({ type: LOADING_STOP });
          callback();
        })
        .catch((e) => {
          dispatch(httpErrorResponse(e));
          errorCallback();
        });
    }
  };
};

export const addCommentToPatient = (patientId, text) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails;
    const currentDate = new Date().toISOString();
    let requestData = {
      payload: {
        patientHistoryId: 0,
        patientId: patientId,
        type: attachmentTypes.COMMENT.value,
        comment: text,
        links: null,
        createdBy: userDetails.user.profile.userName,
        createdDate: currentDate,
        modifiedBy: userDetails.user.profile.userName,
        modifiDate: currentDate
      }
    };
    dispatch({ type: LOADING_START });
    createDataWithToken(
      'patients/v1/patient/history/save',
      userDetails.token,
      requestData
    )
      .then((response) => {
        dispatch({
          type: PATIENT_HISTORY_ADDED,
          payload: response.data
        });
        dispatch({ type: LOADING_STOP });
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

export const loadSpecificTypeHistory = (
  patientId,
  reportType,
  pageNo,
  pageSize
) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails;
    dispatch({ type: LOADING_START });
    const requestData = {
      pageNo: pageNo || 0,
      pageSize: pageSize || 10,
      sortBy: 'createdDate',
      sortDirection: 'DESC',
      filters: {
        patientId: patientId,
        type: reportType
      }
    };
    createDataWithToken(
      `patients/v1/patient/history/find`,
      userDetails.token,
      requestData
    )
      .then(async (response) => {
        // let dataArray = await dataFormatDateWise(response.data);
        dispatch({
          type: FETCH_SPECIFIC_TYPE_HISTORY,
          payload: response,
          reportType: reportType
        });

        console.log('Response: ', response);
        dispatch({ type: LOADING_STOP });
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

export const resetPatientHistory = () => {
  return (dispatch) => {
    dispatch({ type: RESET_PATIENT_HISTORY });
  };
};

export const resetSelectedPatient = () => {
  return (dispatch) => {
    dispatch({ type: RESET_SELECTED_PATIENT });
  };
};

export const uploadFile = (patientId, type, selectedFile, callback) => {
  return (dispatch, getState) => {
    if (!selectedFile) {
      return;
    }
    const formData = new FormData();
    formData.append('file', selectedFile);
    createDataWithTokenNoContentType(
      `patients/v1/patient/${patientId}/attachment/${type}`,
      getState().user.userDetails.token,
      formData
    )
      .then((response) => {
        dispatch({
          type: PATIENT_HISTORY_ADDED,
          payload: response.data
        });
        showSuccessToaster('File Attached');
        callback();
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

export const addPatientVitals = (
  historyId,
  formData,
  appointmentId,
  slotId,
  callback
) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails;
    const currentDate = new Date().toISOString();
    let requestData = {
      payload: {
        patientHistoryId: historyId,
        patientId: formData.patientId,
        appointmentId: appointmentId,
        type: attachmentTypes.VITALS.value,
        comment: '',
        data: {
          ...formData,
          appointmentId: appointmentId,
          slotId: slotId
        },
        links: null,
        createdBy: userDetails.user.profile.userName,
        createdDate: currentDate,
        modifiedBy: userDetails.user.profile.userName,
        modifiDate: currentDate
      }
    };
    dispatch({ type: LOADING_START });
    createDataWithToken(
      'patients/v1/patient/history/save',
      userDetails.token,
      requestData
    )
      .then((response) => {
        dispatch({
          type: PATIENT_VITALS_WITH_APPOINTMENT_ID,
          payload: response.data
        });
        callback(response.data);
        dispatch({ type: LOADING_STOP });
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

export const loadVitalsWithAppointmentId = (
  patientId,
  appointmentId,
  pageNo,
  pageSize
) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails;
    dispatch({ type: LOADING_START });
    const requestData = {
      pageNo: pageNo || 0,
      pageSize: pageSize || 10,
      sortBy: 'createdDate',
      sortDirection: 'DESC',
      filters: {
        patientId: patientId,
        appointmentId: appointmentId,
        type: attachmentTypes.VITALS.value
      }
    };
    createDataWithToken(
      `patients/v1/patient/history/find`,
      userDetails.token,
      requestData
    )
      .then(async (response) => {
        // let dataArray = await dataFormatDateWise(response.data);
        dispatch({
          type: PATIENT_VITALS_WITH_APPOINTMENT_ID,
          payload: response.data[0]
        });

        console.log('Response: ', response);
        dispatch({ type: LOADING_STOP });
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

export const setSelectedPatient = (selectedPatient) => {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_PATIENT,
      payload: selectedPatient
    });
  };
};
