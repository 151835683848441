export default {
  REPORT: {
    name: 'Report',
    label: 'Report',
    value: 'REPORT',
    color: 'primary'
  },
  PRECRIPTION: {
    name: 'Prescription',
    label: 'Prescription',
    value: 'PRECRIPTION',
    color: 'error'
  },
  NOTES: {
    name: 'Notes',
    label: 'Notes',
    value: 'NOTES',
    color: 'error'
  },
  COMMENT: {
    name: 'Comment',
    label: 'Comment',
    value: 'COMMENT',
    color: 'success'
  },
  INITIAL_HISTROY: {
    name: 'Initial History',
    label: 'Initial History',
    value: 'INITIAL_HISTROY',
    color: 'info'
  },
  VITALS: {
    name: 'Vitals',
    label: 'Vitals',
    value: 'VITALS',
    color: 'info'
  },
  RECEIPT: {
    name: 'Receipts',
    label: 'Receipts',
    value: 'RECEIPT',
    color: 'info'
  }
};
