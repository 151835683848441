import { createDataWithToken, updateDataWithToken } from 'src/helpers/db';
import { LOADING_START, LOADING_STOP, httpErrorResponse } from './app';
import { showInfoToaster, showSuccessToaster } from 'src/helpers/toaster';
import appointmentStatus from 'src/constants/appointmentStatus';
import moment from 'moment';
import salutation from 'src/constants/salutation';

export const FETCH_ALL_APPOINTMENTS_FOR_DOCTOR =
  'FETCH_ALL_APPOINTMENTS_FOR_DOCTOR';
export const FETCH_ALL_APPOINTMENTS_FOR_BRANCH =
  'FETCH_ALL_APPOINTMENTS_FOR_BRANCH';
export const BOOK_APPOINTMENT = 'BOOK_APPOINTMENT';
export const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT';
export const UPDATE_APPOINTMENT__BY_DOCTOR = 'UPDATE_APPOINTMENT__BY_DOCTOR';
export const APPOINTMENT_SELECTED = 'APPOINTMENT_SELECTED';
export const REMOVE_SELECTED_APPOINTMENT = 'REMOVE_SELECTED_APPOINTMENT';
export const DOCTOR_SLOTS_CREATED = 'DOCTOR_SLOTS_CREATED';
export const DOCTOR_SLOTS_FETCHED_BY_DATE = 'DOCTOR_SLOTS_FETCHED_BY_DATE';
export const BULK_SLOT_DELETE = 'BULK_SLOT_DELETE';
export const SLOT_DELETED_BY_ID = 'SLOT_DELETED_BY_ID';
export const DELETE_APPOINTMENT = 'DELETE_APPOINTMENT';
export const READ_TODAYS_QUEUE_FOR_DOCTOR = 'READ_TODAYS_QUEUE_FOR_DOCTOR';
export const PATIENTS_PREVIOUS_APPOINTMENTS = 'PATIENTS_PREVIOUS_APPOINTMENTS';

export const readTodaysQueueForDoctor = (doctorId) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails;
    const workspaceBranch = getState().company.workspaceBranch;
    const doctors = getState().doctor.doctors;
    const requestData = {
      pageNo: 0,
      pageSize: 20,
      sortBy: 'createdDate',
      sortDirection: 'ASC',
      filters: {
        appointment: {
          doctorId: doctorId
        },
        slot: {
          branchId: workspaceBranch,
          slotDate: moment(new Date()).format('YYYY-MM-DD')
        }
      }
    };

    dispatch({ type: LOADING_START });
    createDataWithToken(
      'appointments/v1/appointment/find',
      userDetails.token,
      requestData
    )
      .then((response) => {
        let filteredAppointments = [];
        response.data.forEach((item) => {
          if (
            item.appointment.status === appointmentStatus.CONFIRMED.value ||
            item.appointment.status === appointmentStatus.BOOKED.value
          ) {
            filteredAppointments.push(item);
          }
        });
        dispatch({
          type: READ_TODAYS_QUEUE_FOR_DOCTOR,
          payload: {
            appointments: filteredAppointments,
            doctorId: doctorId,
            doctorDetails: doctors.find(
              (item) => item.profile.userName === doctorId
            ),
            totalCount: response.totalCount
          }
        });

        dispatch({ type: LOADING_STOP });
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

export const readTodaysAppointmentsForDoctor = (doctorId) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails;
    const workspaceBranch = getState().company.workspaceBranch;
    const requestData = {
      pageNo: 0,
      pageSize: 20,
      sortBy: 'createdDate',
      sortDirection: 'ASC',
      filters: {
        appointment: {
          doctorId: doctorId
        },
        slot: {
          branchId: workspaceBranch,
          slotDate: moment(new Date()).format('YYYY-MM-DD')
        }
      }
    };

    dispatch({ type: LOADING_START });
    createDataWithToken(
      'appointments/v1/appointment/find',
      userDetails.token,
      requestData
    )
      .then((response) => {
        dispatch({
          type: FETCH_ALL_APPOINTMENTS_FOR_DOCTOR,
          payload: response.data,
          totalCount: response.totalCount
        });
        dispatch({ type: LOADING_STOP });
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

export const readAllAppointmentsByDate = (
  pageNumber,
  pageSize,
  formState,
  callback
) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails;
    const requestData = {
      pageNo: pageNumber,
      pageSize: pageSize,
      sortBy: 'appointmentTime',
      sortDirection: 'ASC',
      filters: {
        appointment: {
          doctorId: formState.doctorId,
          appointmentDate: formState.appointmentDate
        }
      }
    };

    dispatch({ type: LOADING_START });
    createDataWithToken(
      'appointments/v1/appointment/find',
      userDetails.token,
      requestData
    )
      .then((response) => {
        dispatch({
          type: FETCH_ALL_APPOINTMENTS_FOR_BRANCH,
          payload: response.data,
          toatlCount: response.totalCount
        });
        callback(response);
        dispatch({ type: LOADING_STOP });
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

export const bookAppointment = (formData, callback, errorCallback) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails;
    const userName = userDetails.user.profile.userName;
    const currentDate = new Date().toISOString();
    dispatch({ type: LOADING_START });
    const requestData = {
      payload: {
        appointmentId: 0,
        ...formData,
        status: formData.isPresent
          ? appointmentStatus.CONFIRMED.value
          : appointmentStatus.BOOKED.value,
        cancellationRemarks: formData.cancellationRemarks || '',
        createdBy: userName,
        createdDate: currentDate,
        modifiedBy: userName,
        modifiDate: currentDate
      }
    };
    createDataWithToken(
      'appointments/v1/appointment/book',
      userDetails.token,
      requestData
    )
      .then((response) => {
        dispatch({
          type: BOOK_APPOINTMENT,
          payload: response
        });
        dispatch({ type: LOADING_STOP });
        showSuccessToaster(
          `Appointment scheduled on ${moment(
            new Date(response.slot.slotDate)
          ).format('DD/MM/YYYY')} at ${moment(
            response.slot.slotTime,
            'hh:mm:ss'
          ).format('hh:mm')}`
        );
        callback();
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
        errorCallback(e);
      });
  };
};

export const updateAppointment = (
  slotData,
  updatedStatus,
  successCallback,
  errorCallback
) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails;
    const userName = userDetails.user.profile.userName;
    const currentDate = new Date().toISOString();
    dispatch({ type: LOADING_START });
    console.log(slotData, updatedStatus);
    const requestData = {
      payload: {
        ...slotData.appointment,
        status: appointmentStatus[updatedStatus].value,
        modifiedBy: userName,
        modifiDate: currentDate
      }
    };
    updateDataWithToken(
      'appointments/v1/appointment/update',
      userDetails.token,
      requestData
    )
      .then((response) => {
        if (response.appointment.status === appointmentStatus.CANCELLED.value) {
          dispatch({
            type: DELETE_APPOINTMENT,
            payload: response,
            slotId: slotData.slotId
          });
        } else {
          dispatch({
            type: UPDATE_APPOINTMENT,
            payload: response,
            slotId: slotData.slotId
          });
        }

        dispatch({ type: LOADING_STOP });
        showSuccessToaster(
          `Status for Appointment at ${moment(
            slotData.slotTime,
            'hh:mm:ss'
          ).format('hh:mm')} updated to : ${
            appointmentStatus[updatedStatus].name
          }`
        );
        successCallback();
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
        errorCallback();
      });
  };
};

/**
 * This is used in doctors board where doctor mark it as checked
 * @param {*} appointment
 * @param {*} status
 * @param {*} callback
 * @returns
 */
export const updateAppointmentStatus = (appointment, status, callback) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails;
    const userName = userDetails.user.profile.userName;
    const currentDate = new Date().toISOString();
    dispatch({ type: LOADING_START });
    const requestData = {
      payload: {
        ...appointment,
        status: status,
        modifiedBy: userName,
        modifiDate: currentDate
      }
    };
    updateDataWithToken(
      'appointments/v1/appointment/update',
      userDetails.token,
      requestData
    )
      .then((response) => {
        dispatch({
          type: UPDATE_APPOINTMENT__BY_DOCTOR,
          payload: response
        });
        dispatch({ type: LOADING_STOP });
        callback();
        showSuccessToaster(`Appointment updated`);
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

/**
 * This is to create doctors slot for specified details
 * @param {*} formData
 * @param {*} successCallback
 * @returns
 */
export const createSlotsByDoctorId = (formData, successCallback) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails;
    const requestData = {
      payload: {
        ...formData.inputValues,
        startDate: moment(new Date(formData.inputValues.startDate)).format(
          'YYYY-MM-DD'
        ),
        endDate: moment(new Date(formData.inputValues.endDate)).format(
          'YYYY-MM-DD'
        ),
        startTime: formData.inputValues.startTime,
        endTime: formData.inputValues.endTime
      }
    };
    createDataWithToken(
      'appointments/v1/appointment/slots/create',
      userDetails.token,
      requestData
    )
      .then((response) => {
        if (response.data.length > 0) {
          dispatch({
            type: DOCTOR_SLOTS_CREATED,
            payload: response
          });
          dispatch({ type: LOADING_STOP });
          successCallback(response);
          showSuccessToaster(`Slots created successfully`);
        }
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

/**
 * To fetch all slots for specific date for doctor using doctorId and date
 * @param {*} formData
 * @returns
 */
export const fetchDoctorsSlotByDate = (formData) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails;
    const doctors = getState().doctor.doctors;
    const workspaceBranch = getState().company.workspaceBranch;
    const requestData = {
      ...formData.inputValues,
      branchId: workspaceBranch,
      slotDate: moment(new Date(formData.inputValues.slotDate)).format(
        'YYYY-MM-DD'
      )
    };
    const selectedDoctor = doctors.find(
      (item) => item.profile.userName === formData.inputValues.doctorId
    );
    createDataWithToken(
      'appointments/v1/appointment/slots/doctor/bydate',
      userDetails.token,
      requestData
    )
      .then((response) => {
        dispatch({
          type: DOCTOR_SLOTS_FETCHED_BY_DATE,
          payload: {
            ...response.data[0],
            doctorId:
              salutation[selectedDoctor.profile.title].name +
              ' ' +
              selectedDoctor.profile.firstName +
              ' ' +
              selectedDoctor.profile.lastName
          }
        });

        if (response.data && response.data.length === 0) {
          showInfoToaster('No slots found. Please generate slots for today');
        }
        dispatch({ type: LOADING_STOP });
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

export const deleteSlotWithSlotIDsArray = (slotIds, successCallback) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails;
    const branchId = getState().company.workspaceBranch;

    const requestData = {
      payload: slotIds
    };
    createDataWithToken(
      `appointments/v1/appointment/slots/delete?branchId=${branchId}`,
      userDetails.token,
      requestData
    )
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: BULK_SLOT_DELETE,
            payload: slotIds
          });
          showSuccessToaster(
            `Slot with slot ID/s ${slotIds.join(',')} deleted`
          );
          successCallback();
        }
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

export const updateAppointmentPaymentStatus = (
  slotData,
  formData,
  successCallback
) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails;
    dispatch({ type: LOADING_START });
    const requestData = {
      payload: {
        ...formData
      }
    };
    updateDataWithToken(
      'appointments/v1/appointment/update/payment',
      userDetails.token,
      requestData
    )
      .then((response) => {
        console.log('Payment Status Updated: ', response);
        dispatch({
          type: UPDATE_APPOINTMENT,
          payload: response,
          slotId: slotData.slotId
        });
        successCallback(response);

        dispatch({ type: LOADING_STOP });
        showSuccessToaster(
          `Payment status for Appointment at ${moment(
            slotData.slotTime,
            'hh:mm:ss'
          ).format('hh:mm')} updated`
        );
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
        // errorCallback();
      });
  };
};

export const readAppointmentByAppointmentId = (
  appointmentId,
  successCallback
) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails;
    const requestData = {
      pageNo: 0,
      pageSize: 1,
      sortBy: 'createdDate',
      sortDirection: 'ASC',
      filters: {
        appointment: {
          appointmentId: appointmentId
        }
      }
    };
    createDataWithToken(
      'appointments/v1/appointment/find',
      userDetails.token,
      requestData
    )
      .then((response) => {
        successCallback(response.data[0]);
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

/**
 * Reads all previous and current appointments by patient Id
 */
export const fetchAppointmentsByPatientId = (
  patientId,
  doctorId,
  page,
  rowsPerPage,
  successCallback
) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails;
    const requestData = {
      pageNo: page,
      pageSize: rowsPerPage,
      sortBy: 'createdDate',
      sortDirection: 'DESC',
      filters: {
        appointment: {
          patientId: patientId,
          doctorId: doctorId
        }
      }
    };
    createDataWithToken(
      'appointments/v1/appointment/find',
      userDetails.token,
      requestData
    )
      .then((response) => {
        dispatch({
          type: PATIENTS_PREVIOUS_APPOINTMENTS,
          payload: response
        });
        successCallback(response);
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};
